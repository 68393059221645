
export function sortFuntionStringintoNumericVal(a,b){
    a = a.replace(/Rs. /, '');
    b = b.replace(/Rs. /, '');
    a = a.replace(/INR /, '');
    b = b.replace(/INR /, '');
    const x = parseFloat(a.replace(/,/g, ''));
    const y = parseFloat(b.replace(/,/g, ''));
    return (x < y ? -1 : (x > y ? 1 : 0));
}
export function sortFuntionNumericVal(x,y){
    return (x < y ? -1 : (x > y ? 1 : 0));
}

