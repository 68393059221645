<template>
  <div>
    <b-row v-if="client.status" class="content-header">
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0 text-white">
              <b-avatar
                class="mr-1"
                size="32"
                :src="client.logo"
                :text="avatarText(client.name)"
                :variant="client.logo ? `white` : `primary`"
              />
              {{ client.name }}
            </h2>
            <div class="breadcrumb-wrapper breadcrumb-container">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top text-white"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                  class="text-white"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-alert :show="!client.status & !isReportDataLoading" variant="danger">
      <h4 class="alert-heading">Error fetching Client data</h4>
      <div class="alert-body">
        {{ errorMessage }}. Check
        <b-link class="alert-link" :to="{ name: 'client-list' }">
          Clients List
        </b-link>
        for other clients.
      </div>
    </b-alert>
    <b-alert
      :show="client.status && !client.integrations.googleAds"
      variant="danger"
    >
      <h4 class="alert-heading">Error fetching the Google Ads Reports</h4>
      <div class="alert-body">
        Your Google Ads account was not integrated with this account, or we lost
        the access to it. Click
        <b-link
          class="alert-link"
          :to="`/client/${client.slug}?next=google-ads-integration`"
        >
          here
        </b-link>
        to connect or reconnect your account.
      </div>
    </b-alert>
    <b-alert
      :show="
        client.status &&
        client.integrations.googleAds &&
        !client.googleAdsCustomerID
      "
      variant="danger"
    >
      <h4 class="alert-heading">Error fetching the Google Ads Reports</h4>
      <div class="alert-body">
        You don't have a customer account linked to your account. Click
        <b-link
          class="alert-link"
          :to="`/client/${client.slug}?next=google-ads-customer-selection`"
        >
          here
        </b-link>
        to select a customer account.
      </div>
    </b-alert>

    <!-- DASHBOARD -->
    <div
      v-if="
        client.status &&
        client.integrations.googleAds &&
        client.googleAdsCustomerID
      "
    >
      <b-overlay :show="isReportDataLoading" rounded="sm" class="comparison-dashboard-overlay">
        <b-row class="match-height">
          <b-col cols="4">
            <date-selector />
          </b-col>
          <b-col cols="8">
            <over-view-card
              v-if="
                client.status &&
                client.integrations.googleAds &&
                client.googleAdsCustomerID
              "
              :isDataLoading="isReportDataLoading"
              :summaryData="charts.totalData"
              :comparisonData="charts.data"
              :chartData="charts.data"
              :startDate="startDate"
              :endDate="endDate"
            />
          </b-col>
        </b-row>

        <trigger-creator
          :client="client._id"
          buttonSize="lg"
          iconSize="22"
        ></trigger-creator>

        <data-table title="Daily Report" type="date" :chartData="charts.data" />
        <data-table
          title="Campaign Report"
          type="campaign"
          :chartData="campaigns"
        />
        <filters
          v-if="client._id"
          :clientID="client._id"
          @filterReportData="filterReportData"
        />
      </b-overlay>
    </div>
    <!-- DASHBOARD -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import OverViewCard from "./OverViewCard.vue";
import DataTable from "../DataTable.vue";
import GraphSelector from "../GraphSelector.vue";
import GraphDisplay from "../GraphDisplay.vue";
import Filters from "../Filters.vue";
import DateSelector from "./DateSelector.vue";

import axios from "axios";
import { getUserToken } from "@/auth/utils";
import moment from "moment";

export default {
  data() {
    return {
      client: {
        status: false,
        integrations: {
          googleAds: null,
        },
        googleAdsCustomerID: null,
      },
      errorMessage: "",
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      isReportDataLoading: true,
      campaigns: [],
      charts: {
        data: [],
        totalData: {},
      },
      graphs: [],
      startDate: "",
      endDate: "",
    };
  },
  components: {
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    BCard,
    BButton,
    OverViewCard,
    DataTable,
    BOverlay,
    GraphSelector,
    GraphDisplay,
    DateSelector,
    Filters,
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    getClient() {
      const slug = this.$route.params.slug;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          this.graphs = response.data.client.googleAdsGraphs
            ? response.data.client.googleAdsGraphs
            : [];
          this.breadcrumbs.push({
            text: response.data.client.name,
            to: `/client/${response.data.client.slug}`,
            active: false,
          });
          this.breadcrumbs.push({
            text: "Google Ads",
            to: `/client/${response.data.client.slug}/dashboard/google-ads`,
            active: false,
          });
          this.breadcrumbs.push({
            text: "Compare",
            to: `/client/${response.data.client.slug}/dashboard/google-ads/compare`,
            active: true,
          });

          if (
            response.data.client &&
            response.data.client.integrations.googleAds &&
            response.data.client.googleAdsCustomerID
          ) {
            this.getReportData();
          }
        })
        .catch((error) => {
          console.log(error);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
        });
    },
    getReportData(
      startDate,
      endDate,
      campaignType = null,
      campaigns = null,
      adGroups = null
    ) {
      this.isReportDataLoading = true;
      if (!startDate || !endDate) {
        startDate = moment().subtract(2, "days").format("YYYY-MM-DD");
        endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      }
      this.startDate = startDate;
      this.endDate = endDate;

      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-report?start-date=${startDate}&end-date=${endDate}${additionalQueryParams}`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isReportDataLoading = false;
          this.campaigns = response.data.campaigns;
          this.charts = response.data.charts;
        })
        .catch((error) => {
          console.log(error);
          this.isReportDataLoading = false;
        });
    },
    filterReportData(filter) {
      this.getReportData(
        this.startDate,
        this.endDate,
        filter.selectedCampaignType,
        filter.selectedCampaigns,
        filter.selectedAdGroups
      );
    },
  },
  mounted() {
    this.getClient();
  },
};
</script>

<style lang="scss">
.comparison-dashboard-overlay {
  .rounded-sm {
    inset: -20px !important;
    height: auto !important;
  }
}
</style>