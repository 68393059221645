<template>
  <b-card no-body class="card-statistics">
    <!-- <b-card-header>
      <b-card-title>
        <h5>
          Data Comparison between
          <b-badge variant="light-danger" pill>{{ startDate }}</b-badge> and
          <b-badge variant="light-danger" pill>{{ endDate }}</b-badge>
        </h5>
      </b-card-title>
    </b-card-header> -->
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItemsData"
          :key="item.subtitle"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <div class="text-center mb-1">
            <b-avatar size="48" :variant="item.color">
              <feather-icon size="24" :icon="item.icon" />
            </b-avatar>
          </div>
          <b-media class="text-center" no-body>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
                <div
                  :class="item.comparisonData.class"
                  class="font-weight-bolder"
                >
                  <feather-icon :icon="item.comparisonData.icon"></feather-icon>
                  {{ item.comparisonData.data }}
                </div>
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
} from "bootstrap-vue";
import commaNumber from "comma-number";
import millify from "millify";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
  },
  props: {
    summaryData: {
      type: Object,
      default: () => {
        return {
          clicks: 0,
          conversions: 0,
          costMicros: 0,
          impressions: 0,
          ctr: 0,
          avg_cpm: 0,
          cpc: 0,
          avg_cpc: 0,
        };
      },
    },
    comparisonData: {
      type: Array,
      default: () => {
        return [
          {
            clicks: 0,
            conversions: 0,
            costMicros: 0,
            impressions: 0,
            ctr: 0,
            avg_cpm: 0,
            cpc: 0,
            avg_cpc: 0,
          },
          {
            clicks: 0,
            conversions: 0,
            costMicros: 0,
            impressions: 0,
            ctr: 0,
            avg_cpm: 0,
            cpc: 0,
            avg_cpc: 0,
          },
        ];
      },
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  computed: {
    statisticsItemsData() {
      let statisticsItems = [];
      let summaryData = this.summaryData;
      let dataKeys = Object.keys(summaryData);
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i];
        let comparisonData = this.getComparisonData(dataKey);
        let title = dataKey;
        title = title.replace("_", " ").toLowerCase();
        let dataToPush = this.getFormattedText(summaryData[dataKey], title);
        if (dataToPush) {
          statisticsItems.push({ ...dataToPush, comparisonData });
        }
      }

      return statisticsItems;
    },
  },
  data() {
    return {};
  },
  methods: {
    getFormattedText(text, field) {
      field = field.toLowerCase();
      if (field === "clicks") {
        return {
          icon: "MousePointerIcon",
          color: "light-warning",
          title: millify(text),
          subtitle: "Clicks",
          customClass: "mb-2 mb-xl-0",
        };
      } else if (field === "impressions") {
        return {
          icon: "EyeIcon",
          color: "light-pink",
          title: millify(text),
          subtitle: "Impressions",
          customClass: "mb-2 mb-xl-0",
        };
      } else if (field === "costmicros") {
        return {
          icon: "DollarSignIcon",
          color: "light-info",
          title: "₹ " + millify(text),
          subtitle: "Cost",
          customClass: "mb-2 mb-xl-0",
        };
      } else if (field === "ctr") {
        return false;
        return {
          icon: "DollarSignIcon",
          color: "light-success",
          title: (text * 100).toFixed(2) + "%",
          subtitle: "CTR",
          customClass: "mb-2 mb-xl-0",
        };
      } else if (field === "avg cpm") {
        return {
          icon: "TargetIcon",
          color: "light-danger",
          title: "₹ " + (text * 1000).toFixed(2),
          subtitle: "Avg. CPM",
          customClass: "mb-2 mb-xl-0",
        };
      } else if (field === "avg cpc") {
        return {
          icon: "DollarSignIcon",
          color: "light-pink",
          title: "₹ " + text.toFixed(2),
          subtitle: "Avg. CPC",
          customClass: "mb-2 mb-xl-0",
        };
      } else if (field === "cpc") {
        return false;
        return {
          icon: "DollarSignIcon",
          color: "light-success",
          title: text.toFixed(2),
          subtitle: "CPC",
          customClass: "mb-2 mb-xl-0",
        };
      } else if (field === "conversions") {
        return {
          icon: "RefreshCcwIcon",
          color: "light-success",
          title: millify(text.toFixed(2)),
          subtitle: "Conversions",
          customClass: "mb-2 mb-xl-0",
        };
      } else {
        return false;
        return {
          icon: "DollarSignIcon",
          color: "light-success",
          title: text.toFixed(2),
          subtitle: field.toUpperCase(),
          customClass: "mb-2 mb-xl-0",
        };
      }
    },
    getComparisonData(field) {
      let dayBeforeYesterday = this.comparisonData[0];
      let yesterday = this.comparisonData[1];

      let DBYfieldData = dayBeforeYesterday[field];
      let YfieldData = yesterday[field];

      let data = {
        data: YfieldData - DBYfieldData,
        sign: YfieldData - DBYfieldData < 0 ? "negative" : "positive",
        class: YfieldData - DBYfieldData < 0 ? "text-danger" : "text-success",
        icon:
          YfieldData - DBYfieldData < 0 ? "ChevronsDownIcon" : "ChevronsUpIcon",
      };

      if (field === "costMicros" && data.data > 0) {
        data.sign = "negative";
        data.class = "text-danger";
        data.icon = "ChevronsUpIcon";
      } else if (field === "costMicros" && data.data <= 0) {
        data.sign = "positive";
        data.class = "text-success";
        data.icon = "ChevronsDownIcon";
      }
      data.data = Math.abs(data.data).toFixed(2);
      return data;
    },
  },
};
</script>
