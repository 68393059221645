<template>
  <div>
    <b-card v-if="filter" id="filters-card">
      <b-card-title>
        <h3 class="app-title md my-2">Filter Report</h3>
      </b-card-title>
      <b-row>
        <b-col cols="12" class="mt-1">
          <h6>Campaign Type</h6>
          <b-form-group>
            <v-select
              :loading="isCampaignTypesLoading"
              placeholder="Select Campaign Type"
              v-model="selectedCampaignType"
              label="Campaign Type"
              :options="campainTypes"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mt-1">
          <h6>Campaign(s)</h6>
          <b-form-group>
            <v-select
              label="name"
              :loading="isCampaignsLoading"
              placeholder="Select Campaign(s)"
              v-model="selectedCampaigns"
              multiple
              :options="campaigns"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mt-1">
          <h6>Ad Group(s)</h6>
          <b-form-group>
            <v-select
              label="name"
              :loading="isAdGroupsLoading"
              placeholder="Select Ad Group(s)"
              v-model="selectedAdGroups"
              multiple
              :options="adGroups"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="text-center mt-2 mb-2">
        <b-button
          @click="filterReportData"
          :disabled="
            !selectedCampaigns.length &&
            !selectedCampaignType &&
            !selectedAdGroups.length
          "
          variant="primary"
          >Filter</b-button
        >
      </div>
    </b-card>
    <div id="google-ads-filter-float">
      <b-button @click="filter = !filter" size="md" variant="primary" class="">
        <div v-if="!filter">
          <feather-icon size="14" class="" icon="FilterIcon" />
          Filter Report
        </div>
        <feather-icon v-else size="14" icon="XCircleIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BCardText,
  BCardTitle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BButton,
    BCard,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
  },
  props: {
    clientID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filter: false,
      selectedCampaignType: "",
      selectedCampaigns: [],
      selectedAdGroups: [],
      campainTypes: [],
      campaigns: [],
      adGroups: [],
      isCampaignTypesLoading: true,
      isCampaignsLoading: true,
      isAdGroupsLoading: true,
    };
  },
  methods: {
    getCampaignTypes() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-campaign-types`,
          {
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isCampaignTypesLoading = false;
          this.campainTypes = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isCampaignTypesLoading = false;
        });
    },
    getCampaigns() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-campaigns`,
          {
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isCampaignsLoading = false;
          this.campaigns = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isCampaignsLoading = false;
        });
    },
    filterReportData() {
      let selectedCampaigns = [];
      let selectedAdGroups = [];
      if (this.selectedCampaigns.length) {
        this.selectedCampaigns.map((campaign) => {
          selectedCampaigns.push(campaign.name);
        });
      }
      if (this.selectedAdGroups.length) {
        this.selectedAdGroups.map((adGroup) => {
          selectedAdGroups.push(adGroup.name);
        });
      }
      this.$emit("filterReportData", {
        selectedCampaignType: this.selectedCampaignType,
        selectedCampaigns: selectedCampaigns.join(),
        selectedAdGroups: selectedAdGroups.join(),
      });
      this.filter = false;
    },
    getAdGroups() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-ad-groups`,
          {
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isAdGroupsLoading = false;
          this.adGroups = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isAdGroupsLoading = false;
        });
    },
  },
  mounted() {
    this.getCampaignTypes();
    this.getCampaigns();
    this.getAdGroups();
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#google-ads-filter-float {
  position: fixed;
  right: 30px;
  bottom: 40px;
  z-index: 9;

  .btn-primary {
    box-shadow: 0 0 10px #7367f0;
  }
}

#filters-card {
  box-shadow: 0px 0px 60px 20px rgb(34 41 47 / 15%);
  position: fixed;
  right: 30px;
  bottom: 65px;
  width: 100%;
  max-width: 520px;
  z-index: 9;
}
</style>>