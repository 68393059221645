<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="lineAreaChartSpline.chartOptions"
        :series="lineAreaChartSpline.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    labels: {
      type: Array,
      default: () => {
        return [];
      },
    },
    series: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      lineAreaChartSpline: {
        series: this.series,
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: "smooth",
            width: 2,
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: this.labels,
          },
          yaxis: [
            {
              title: {
                text: this.series[0].name.toUpperCase(),
              },
            },
            {
              opposite: true,
              title: {
                text: this.series[1].name.toUpperCase(),
              },
            },
          ],
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 1,
              opacityFrom: 0.4,
              opacityTo: 0.1,
              stops: [0, 90, 100],
            },
          },
          tooltip: {
            shared: false,
          },
          colors: ["#FF7E21", "#4D21FF"],
        },
      },
    };
  },
};
</script>
