<template>
  <div>
    <b-card title="Add Graph" class="screenSelectorAlignment">
      <b-card-text> Select metrics to display the graph </b-card-text>
      <b-row>
        <b-col cols="2">
          <b-form-input v-model="title" placeholder="Graph Title" class="graphInput"/>
        </b-col>
        <b-col cols="4">
          <v-select v-model="metric1" label="title" placeholder="Select Metric 1" :options="options" class="selectGraph"/>
        </b-col>
        <b-col cols="4">
          <v-select v-model="metric2" label="title" placeholder="Select Metric 2" :options="options" class="selectGraph"/>
        </b-col>
        <b-col cols="2" class="d-flex align-items-center">
          <b-button :disabled="!isValidOptions" @click="addGraph" variant="primary">Add Graph <feather-icon
              icon="PlusIcon"></feather-icon></b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BFormInput,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
  },
  props: {
    businessType:{
      type:String
    },
    metrics: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    options() {
      let filteredSArray = {}
      if (this.businessType == "leadgen") {
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Leads",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }else if(this.businessType == "ecommerce"){
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Purchases",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }else{
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Conversions",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }
      if (this.metrics.length) {
        let metrics = this.metrics[0];
        let options = [];

        let metricKeys = Object.keys(metrics);
        for (let i = 0; i < metricKeys.length; i++) {
          const metricKey = metricKeys[i];
          if (metricKey != "date") {
            options.push(
              { title: filteredSArray[metricKey], value: metricKey }
            );
            // metricKey
          }
        }
        return options;
      } else {
        return [];
      }
    },
    isValidOptions() {
      return this.title && this.metric1 && this.metric2;
    },
  },
  data() {
    return {
      title: "",
      metric1: "",
      metric2: "",
      option: [],
      metricMap: {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Conversions",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
    };
  },
  methods: {
    addGraph() {
      this.$emit("graphAdded", {
        title: this.title,
        metric1: this.metric1.value,
        metric2: this.metric2.value,
      });
      this.title = "";
      this.metric1 = "";
      this.metric2 = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.selectGraph{
  .vs__dropdown-menu {
  top: calc(100% - 28rem) !important;
}
}
.graphInput {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100% !important;
  height: 48px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
</style>