<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12" cols="12">
        <card-statistics-group
          :summaryData="summaryData"
          :comparisonData="comparisonData"
          :startDate="startDate"
          :endDate="endDate"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import CardStatisticsGroup from "./CardStatisticsGroup.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    summaryData: {
      type: Object,
      default: () => {
        return {
          clicks: 0,
          conversions: 0,
          costMicros: 0,
          impressions: 0,
          ctr: 0,
          avg_cpm: 0,
          cpc: 0,
          avg_cpc: 0,
        };
      },
    },
    comparisonData: {
      type: Array,
      default: () => {
        return [
          {
            clicks: 0,
            conversions: 0,
            costMicros: 0,
            impressions: 0,
            ctr: 0,
            avg_cpm: 0,
            cpc: 0,
            avg_cpc: 0,
          },
          {
            clicks: 0,
            conversions: 0,
            costMicros: 0,
            impressions: 0,
            ctr: 0,
            avg_cpm: 0,
            cpc: 0,
            avg_cpc: 0,
          },
        ];
      },
    },
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    chartDataValues() {
      let data = this.chartData;
      let labels = [];
      let clicksData = [{ data: [] }];
      let impressionsData = [{ data: [] }];
      for (let i = 0; i < data.length; i++) {
        const dataObject = data[i];
        labels.push(dataObject.date);
        clicksData[0].data.push(dataObject.clicks);
        impressionsData[0].data.push(dataObject.impressions);
      }
      return {
        labels,
        clicksData,
        impressionsData,
      };
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    CardStatisticsGroup,
  },
};
</script>

<style>
</style>