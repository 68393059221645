<template>
  <div>
    <b-card class="screenAlignmentGoogleAds">
      <b-card-title>
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="card-title">{{ chartData.title }}</h4>
          <div>
            <b-button @click="deleteGraph" v-b-tooltip.hover.bottom="'Delete Graph'" variant="outline-danger"
              class="btn-icon rounded-circle">
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
        </div>
      </b-card-title>
      <apex-line-area-chart v-if="labels.length" :labels="labels" :series="series" />
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardTitle, BButton, VBTooltip } from "bootstrap-vue";
import ApexLineAreaChart from "./ApexLineAreaChart.vue";

export default {
  props: {
    index: {
      type: Number,
      default: 0,
      required: true,
    },
    businessType:{
      type:String
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          title: "",
          metric1: "",
          metric2: "",
        };
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    BCard,
    ApexLineAreaChart,
    BCardTitle,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      labels: [],
      series: [],
      metricMap: {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Conversions",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
    };
  },
  methods: {
    generateLabelsAndSeries() {
      let data = this.data;
      let chartData = this.chartData;
      let labels = [];
      let series = [
        { name: this.mappedMetric1, data: [] },
        { name: this.mappedMetric2, data: [] },
      ];
      for (let i = 0; i < data.length; i++) {
        const campaignData = data[i];
        labels.push(campaignData.date);
        series[0].data.push(
          parseFloat(campaignData[chartData.metric1].toFixed(2))
        );
        series[1].data.push(
          parseFloat(campaignData[chartData.metric2].toFixed(2))
        );
      }

      this.labels = labels;
      this.series = series;
    },
    deleteGraph() {
      if (
        confirm(
          `Do you want to remove the selected Graph - ${this.chartData.title}?`
        )
      ) {
        this.$emit("deleteGraph", this.index);
      }
    },
  },
  computed: {
    mappedMetric1() {
      let filteredSArray = {}
      if (this.businessType == "leadgen") {
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Leads",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }else if(this.businessType == "ecommerce"){
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Purchases",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }else{
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Conversions",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }
      return filteredSArray[this.chartData.metric1] || "";
    },
    mappedMetric2() {
      let filteredSArray = {}
      if (this.businessType == "leadgen") {
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Leads",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }else if(this.businessType == "ecommerce"){
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Purchases",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }else{
        filteredSArray = {
        costMicros: "Cost",
        ROAS: "ROAS",
        allConversionsValue: "Revenue",
        avg_cpc: "Avg. CPC",
        avg_cpm: "Avg. CPM",
        clicks: "Clicks",
        conversionRate: "Conversion Rate %",
        conversions: "Conversions",
        conversionsValue: "Conversions Value",
        costPerConversion: "Cost Per Conversion",
        cpc: "CAC",
        ctr: "CTR",
        date: "Date",
        impressions: "Impressions"
      }
      }
      return filteredSArray[this.chartData.metric2] || "";
    },
  },
  mounted() {
    this.generateLabelsAndSeries();
  },
};
</script>

<style></style>